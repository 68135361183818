import React, { useEffect, useState } from 'react';

// Loacal imports
import PageLoader from '../global/page-loader';
import CTAStrip from '../global/cta.strip';

// Live imports
import {
  NavLink as Link,
  withRouter
} from 'react-router-dom';

function Career() {
  return (
  	<>
      <PageLoader/>
			<div className="breadcrumb-wrapper">
			  <div className="images-overlay"></div>
			  <div className="container">
			    <div className="row">
			      <div className="col-md-12">
			        <div className="page-heading">
			          <h1>Careers</h1>
			          <span className="line"></span>
			        </div>
			        <div className="page-breadcrumb-inner">
			          <div className="page-breadcrumb">
			            <div className="breadcrumb-list">
			              <ul>
			                <li>
                  			<Link exact to="/" activeclass="active">Home</Link>
                  		</li>
			                <li>
                  			<Link exact to="/careers" activeclass="active">Careers</Link>
                  		</li>
			              </ul>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

      <section className="blog-wrapper pt-5">
        <div className="container">
      {/*
          <div className="section_heading ">
	          <div className="row ">
		          <div className="col text-left">
		          	<p>
		          		<Link to="/career/how-to-apply" className="mr-3"><i className="fa fa-question-circle"> </i> How to apply</Link>
		          		<Link to="/contact-us" className="mr-3"><i className="fa fa-envelope"> </i> Send us your resume</Link>
		          	</p>
		          </div>
		          <div className="col text-right">
		          	<p>
		          		<i>*Last update: January 8, 2021</i>
		          	</p>
		          </div>
	          </div>
          </div>*/}

          <div className="row">
          {/**
            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/consumer-welfare-and-relations-coordinator.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/consumer-welfare-and-relations-coordinator.pdf" target="_blank">Consumer Welfare and Relations Coordinator</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>3 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/consumer-welfare-and-relations-coordinator.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/surveyor.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/surveyor.pdf" target="_blank">Surveyor <br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/surveyor.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>**/}

<div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/meter-technician-02.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/meter-technician-02.pdf" target="_blank">Meter Technician<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/meter-technician-02.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

<div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/system-design-analyst.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/system-design-analyst.pdf" target="_blank">System Design Analyst<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/system-design-analyst.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

<div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/cwrc.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/cwrc.pdf" target="_blank">Consumer Welfare and Relations Coordinator<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/cwrc.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>
            
<div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/Line Technician (Area Maintenance).png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/Line Technician (Area Maintenance).pdf" target="_blank">Line Technician (Area Maintenance)<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/Line Technician (Area Maintenance).pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>
            
<div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/Head, Line Technologist.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/Head, Line Technologist.pdf" target="_blank">Head, Line Technologist<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/Head, Line Technologist.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>


<div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/IT Specialist Programmer.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/IT Specialist Programmer.pdf" target="_blank">IT Specialist/Programmer<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/IT Specialist Programmer.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/FINANCIAL AUDITOR1.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/FINANCIAL AUDITOR.pdf" target="_blank">FINANCIAL AUDITOR<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/FINANCIAL AUDITOR.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/HRDO-1.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/HRDO.pdf" target="_blank">Human Resource Development Officer<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/HRDO.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/CODO-1.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/CODO.pdf" target="_blank">Consumer Organization and Development Officer<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/CODO.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/AOE1.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/AOE.pdf" target="_blank">Area Operations Engineer<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/AOE.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/lt2.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/lt2.pdf" target="_blank">Line Technician<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/lt2.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/lt1.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/lt.pdf" target="_blank">Line Technician<br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/lt.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/cac1.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/consumer-account-coordinator.pdf" target="_blank">Consumer Account Coordinator <br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>15 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/consumer-account-coordinator.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/housewiring-inspector.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/housewiring-inspector.pdf" target="_blank">Housewiring Inspector <br/> <br/></a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/housewiring-inspector.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/redo.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/redo.png" target="_blank">Renewable Energy Development Officer</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/consumer-welfare-and-relations-coordinator.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/reda.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/reda.png" target="_blank">Renewable Energy Development Analyst</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/consumer-welfare-and-relations-coordinator.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/meter-technician.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/meter-technician.png" target="_blank">Meter-Technician</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>1 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/meter-technician.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/sst.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/sst.png" target="_blank">Substation Tender</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>3 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/sst.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item blog_card">
                <div className="post-img"> <img src="/assets/uploads/careers/heavy-eqpt-operator.png" alt=""/>
                </div>
                <div className="post-detail">
                  <h5><a href="/assets/uploads/careers/heavy-eqpt-operator.png" target="_blank">Heavy Equipment Operator</a></h5>
                  <div className="post-status">
                    <ul>
                      <li><i className="fa fa-folder"></i> <span><a href="/blog/blog-title">Main Office</a></span></li>
                      <li><i className="fa fa-user"></i> <span>2 Personnel</span></li>
                    </ul>
                  </div>
                  <div className="description">
                    <a href="/assets/uploads/careers/heavy-eqpt-operator.pdf" target="_blank" className="theme-button-one bttn color-one" data-text="Read more"><span>Proceed</span></a> </div>
                </div>
              </div>
            </div>


          </div>
          
        </div>
      </section>

      <CTAStrip/>
  	</>
  );
}

export default withRouter(Career);
